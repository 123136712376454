<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.6rem;">名称：</span>
					<el-input v-model="searchinput" placeholder="请输入名称" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">刷新</el-button>
					<el-button type="primary" @click="search">搜索</el-button>
					<el-button type="info" @click="reset">重置</el-button>
					<el-button type="success" @click="addnew">新增菜单</el-button>
				</div>
			</div>
			<div class="box_cont">
				<!-- <div class="btn_list">
					<div class="mybtn">导出</div>
				</div> -->
				<div style="height: 71vh;padding-top: 10px;box-sizing: border-box;">
					<el-table
						height="650"
					    :data="tableData"
					    style="width: 100%;margin-bottom: 20px;border-left: 1px solid #eee;"
					    row-key="id"
					    border
						:stripe="true"
					    :tree-props="{children: 'chlidren'}">
						
					    <el-table-column prop="title" label="菜单名称" width=""></el-table-column>
						
					    <el-table-column label="菜单图标"  width="120">
							<template slot-scope="scope">
								<i :class="scope.row.icon" style="font-size: 24px;"></i>
							</template>
						</el-table-column>
						
						<el-table-column prop="typename" label="类型" width="">
							<template slot-scope="scope">
								<el-tag :type="scope.row.menutype==1?'info':scope.row.menutype==2?'success':'warning'">
									{{scope.row.menutype==1?'目录':scope.row.menutype==2?'菜单':'按钮'}}
								</el-tag>
							</template>
						</el-table-column>
						
						<el-table-column prop="weigh" label="排序" width=""></el-table-column>
						
						<el-table-column fixed="right" label="操作" width="160">
							<template slot-scope="scope">
								<el-button @click="showedit(scope.row)" type="primary" size="mini">编辑</el-button>
								<el-button @click="deleterow(scope.row)" type="danger" size="mini">删除</el-button>
							</template>
						</el-table-column>
					  </el-table>
				</div>

			</div>
		</div>
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				searchinput: '',
				tableData: [],

			}
		},
		mounted() {
			this.gettable()
		},
		methods: {
			setstatus(row){
				this.$post({
					url:'/admin/ins_company/edit',
					params:row
				}).then((res)=>{
					this.$message.success('操作成功')
					this.gettable()
				})
			},
			addnew(){
				this.$router.push('/system/menu_add')
			},
			reset(){
				this.searchinput=''
				
				this.gettable()
			},
			search(){
				
				this.gettable()
			},
			gettable(){
				this.$post({
					url:'/api/role/index',
					params:{
						title:this.searchinput,						
					}
				}).then((res)=>{
					this.tableData = res.list					
				})
			},
			clickcharu(e){
				console.log(e)
				this.$refs.myinvoiceadd.showkkd = true
			},
			handleSelectionChange(e) {
				console.log(e)
			},
			deleterow(row) {
				this.$confirm('确认删除该条数据吗？')
					.then(_ => {
						this.$post({
							url:'/api/role/del',
							params:{
								id:row.id				
							}
						}).then((res)=>{
							this.$message.success('删除成功')
							this.gettable()
						})
					})
					.catch(_ => {});

			},
			showsetrole(row) {
				console.log(row)
				this.$refs.mymx.showmx = true
			},
			changswitch(row) {
				console.log(row)
			},
			showedit(row) {
				this.$router.push({path:'/system/menu_edit',query:{id:row.id}})
			},

		},
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 25px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.8rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		
	}

	::v-deep .el-table td.el-table__cell {
		
	}

	::v-deep .el-table {
		margin-top: 10px;
		
	}

	::v-deep .el-table__fixed {
		
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}
	::v-deep .el-table td.el-table__cell{
		
	}
</style>